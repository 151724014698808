import React, { useState, useRef, useEffect } from "react";

// Files Import
import EditCard from "../editCard/EditCard";
import StyledMdText from "../../common/components/styledMdText/styledMdText";
import StyledH6Heading from "../../common/components/styledh6heading/StyledH6Heading";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../context/StateContext";
import CardSkeleton from "../skeleton/blogCardSkeleton/BlogCardSkeleton";

const BlogCard = ({ item, setIsModalOpen }) => {
  const [isEditCardOpen, setIsEditCardOpen] = useState(false);
  const { setBlogItem } = useStateContext();
  const editCardRef = useRef();
  const dotsRef = useRef();
  const navigate = useNavigate();

  const handleEditCardToggle = (item) => {
    setIsEditCardOpen(!isEditCardOpen);
    setBlogItem(isEditCardOpen ? null : item);

  };

  const handleClickOutside = (event) => {
    if (
      editCardRef.current &&
      !editCardRef.current.contains(event.target) &&
      dotsRef.current &&
      !dotsRef.current.contains(event.target)
    ) {
      setIsEditCardOpen(false);
    }
  };

  const handleView = () => {
    // Redirect to the single blog page when clicking on view
    navigate(`/single-blog/${item._id}`);
  };

  useEffect(() => {
    if (isEditCardOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditCardOpen]);

  return (
    <div className="flex flex-col items-stretch w-full h-full p-4 bg-white shadow-md rounded-xl">
      <div className="w-full max-h-[180.39px] min-h-[180.39px] ">
        {
          <img
            className="object-cover w-full h-full rounded-xl"
            src={item.imageUrl}
            alt={item.title}
          />
          || <CardSkeleton />
        }
      </div>

      {/* Metadata and Edit Section */}
      <div className="flex flex-col items-start justify-center w-full h-full gap-6 px-2 py-2">
        <div className="relative flex items-center justify-between w-full mt-3">
          <StyledMdText fontColor="text-dark-gray">
            {new Date(item.createdAt).toLocaleDateString()}{" "}
            {/* Format the date */}
          </StyledMdText>
          <div className="relative">
            <img
              ref={dotsRef}
              src="/assets/blog/dots.svg"
              alt="Menu icon"
              className="cursor-pointer"
              onClick={() => handleEditCardToggle(item)}
            />
            {isEditCardOpen && (
              <div
                ref={editCardRef}
                className="absolute right-0 z-10 flex items-center justify-center w-40 h-auto mt-0 bg-white shadow-lg rounded-xl"
              >
                <EditCard
                  onDelete={() => setIsModalOpen(item)}
                  blogId={item._id}
                  onView={handleView}
                  type="blog"
                />
              </div>
            )}
          </div>
        </div>

        {/* Blog Title */}
        <div className="w-full">
          <StyledH6Heading>
            {item.title}
          </StyledH6Heading>
        </div>

        {/* Status Container */}
        <div className="w-full flex justify-start items-center gap-1.5 mt-auto">
          <StyledMdText
            children="Status:"
            fontColor="text-light-gray font-semibold tracking-wide "
          />
          <StyledMdText
            children={item.isActive ? "Published" : "Unpublished"}
            fontColor="text-black"
          />
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
